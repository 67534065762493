@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  /*background-image:url('../Images/SiteImages/headBar.jpg');*/
  background: linear-gradient(112.1deg, #000 11.4%, #333 70.2%);
  background-repeat: repeat-x;
  min-height: 190px;
  height: auto;
  padding-bottom: 10px;
}

  
  .header .content-wrapper {
      padding-top: 20px;
  }


.logo {
  padding-top: 20px;
  object-fit: contain;
  width: 80%
}

.logo-letters{
  display: inline;
  /* display: flex;
  flex-direction: column; */
}

.logo-letter {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  border:solid 3px #000;
  background: linear-gradient(230deg, #eee 11.4%, #999999 70.2%);
  background-repeat: repeat-x;
  margin-right: 4px;  
}

.logo-letter span {
  font-size: 30px;
  font-weight: bold;
  color:#000;
  text-shadow: 0px 1px #666;
}